<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Category</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="category-form">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            @change="onChange($event)"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-file-input
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="category-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        title: "",
        slug: "",
        thumbnail: null,
        thumbnail_has_changed: false,
      },
      category: null,
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.title;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },
    openForm: function (category = null) {
      if (category !== null) {
        this.isEditing = true;
        this.category = category;
        this.fields.title = category.title;
        this.fields.slug = category.slug;
        this.fields.thumbnail = category.thumbnail
          ? new File([category.thumbnail], category.thumbnail)
          : null;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.title = "";
      this.fields.slug = "";
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.category = null;
      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let formData = new FormData();
      formData.append("title", this.fields.title);
      formData.append("slug", this.fields.slug);
      formData.append(
        "thumbnail_has_changed",
        this.fields.thumbnail_has_changed ? 1 : 0
      );

      if (this.fields.thumbnail_has_changed) {
        formData.append("thumbnail", this.fields.thumbnail);
      }

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: formData,
      };

      if (this.isEditing) {
        payload.categoryId = this.category.id;
      }

      this.$store
        .dispatch("grogreen/articles/saveCategory", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    thumbnailHasChanged: function () {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
