<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>{{ pageTitle }} Categories</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Categories"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.categoryForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Category</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="info"
                  class="mr-2"
                  v-on="on"
                  :to="{
                    name: $route.name.substring(0, $route.name.length - 11),
                  }"
                >
                  <v-icon small>mdi-note-multiple</v-icon>
                </v-btn>
              </template>
              <span>View {{ pageTitle }} Articles</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Categories</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="categories"
            no-data-text="No Categories found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.categoryForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDeleteCategory(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <CategoryForm ref="categoryForm" />
    <v-dialog v-model="deleteCategory.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Category</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteCategory.category.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteCategory"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteCategory.loading"
            @click="saveDeleteCategory"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryForm from "./components/CategoryForm";

export default {
  props: {
    pageTitle: String,
  },

  components: {
    CategoryForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Articles",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Slug", value: "slug" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      deleteCategory: {
        dialog: false,
        category: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.breadcrumbs.push(
      {
        text: this.pageTitle,
        disabled: false,
        exact: true,
        to: {
          name: this.$route.name.substring(0, this.$route.name.length - 11),
        },
      },
      {
        text: "Categories",
        disabled: true,
      }
    );
  },

  computed: {
    categories() {
      let categories = this.$store.state.grogreen.articles["categories"];

      if (this.searchTerm !== "") {
        categories = categories.filter((c) => {
          const title = c.title.toLowerCase();
          const slug = c.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          return title.includes(searchTerm) || slug.includes(searchTerm);
        });
      }

      return categories;
    },
  },

  methods: {
    openDeleteCategory(category) {
      this.deleteCategory.category = category;
      this.deleteCategory.dialog = true;
    },

    resetDeleteCategory() {
      this.deleteCategory.dialog = false;
      this.deleteCategory.category = {};
      this.deleteCategory.loading = false;
    },

    saveDeleteCategory() {
      this.deleteCategory.loading = true;

      this.$store
        .dispatch("grogreen/articles/deleteCategory", {
          appId: this.$route.params.id,
          categoryId: this.deleteCategory.category.id,
        })
        .then(() => {
          this.resetDeleteCategory();
        })
        .catch(() => {
          this.deleteCategory.loading = false;
        });
    },
  },
};
</script>
